import axios from "axios";
import store from "../Redux/store";
import {
  setAccessToken,
  setRefreshToken,
  setTokenExpiry,
  clearUser,
} from "../Redux/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const get_timezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

// Log the API URL to check if it's being loaded correctly
console.log("API URL:", process.env.REACT_APP_API_URL);

// Define the base URL
const baseURL = process.env.REACT_APP_API_URL || "http://localhost:8000";
console.log("Using baseURL:", baseURL);

const dispatch = store.dispatch;
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    timezone: get_timezone(),
  },
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    console.log('Request URL:', config.url);
    console.log('Request Method:', config.method);
    
    // Skip auth for Google auth endpoint
    const isGoogleAuthRequest = config.url.includes('/accounts/google-auth/');
    
    // Skip auth for GET requests to blog endpoints and terms-of-use
    const isPublicGetRequest = config.method === 'get' && (
      config.url.includes('/api/blog/posts') || 
      config.url.includes('/api/blog/post') ||
      config.url.includes('/api/terms-of-use')
    );

    if (!isPublicGetRequest && !isGoogleAuthRequest) {
      // Try to get token from localStorage first, then Redux
      const accessToken = localStorage.getItem('accessToken') || store.getState().accessToken;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        console.log('Added Authorization header:', config.headers.Authorization);
      } else {
        console.warn('No access token found for protected request');
      }
    }

    // For FormData requests, don't set Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401 and we haven't tried to refresh the token yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Try to get refresh token from localStorage first, then Redux
        const refreshToken = localStorage.getItem('refreshToken') || store.getState().refreshToken;
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
        console.log('Attempting to refresh token with:', refreshToken);
        
        const response = await axios.post(`${apiUrl}/accounts/token/refresh/`, {
          refresh_token: refreshToken
        });

        console.log('Token refresh response:', response.data);
        
        const { access, refresh, token_expiry } = response.data;
        
        // Store the new tokens in both Redux and localStorage
        dispatch(setAccessToken(access));
        dispatch(setRefreshToken(refresh));
        dispatch(setTokenExpiry(token_expiry));
        
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('tokenExpiry', token_expiry);

        // Update the authorization header
        originalRequest.headers['Authorization'] = `Bearer ${access}`;

        // Retry the original request
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Clear tokens and redirect to login
        dispatch(clearUser());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('user');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
