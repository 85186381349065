// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import api from '../../api/axiosConfig';
// import DOMPurify from 'dompurify';
// import { Link } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import AdComponent from '../AdComponent';

// function BlogPost() {
//   const [post, setPost] = useState(null);
//   const { slug } = useParams();
//   const [isAdmin, setIsAdmin] = useState(false);
//   const auth = useAuth();

//   useEffect(() => {
//     // Check admin status when auth changes
//     if (auth && auth.user && auth.user.user_type === 'Admin') {
//       setIsAdmin(true);
//     } else {
//       setIsAdmin(false);
//     }
//   }, [auth]);

//   useEffect(() => {
//     const fetchPost = async () => {
//       try {
//         const response = await api.get(`/api/blog/posts/${slug}/`);
//         console.log('Fetched post data:', response.data); // Debug log
//         setPost(response.data);
//       } catch (error) {
//         console.error('Error fetching post:', error);
//       }
//     };

//     if (slug) {
//       fetchPost();
//     }
//   }, [slug]);

//   const formatDate = (dateString) => {
//     try {
//       if (!dateString) return 'No date available';
//       const date = new Date(dateString);
//       if (isNaN(date.getTime())) return 'Invalid Date';
      
//       return date.toLocaleDateString('en-US', {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric'
//       });
//     } catch (error) {
//       console.error('Date formatting error:', error);
//       return 'Invalid Date';
//     }
//   };

//   const renderContent = () => {
//     try {
//       if (!post.content_json) return null;

//       // Handle the blocks structure
//       if (post.content_json.blocks) {
//         return post.content_json.blocks.map((block, index) => {
//           if (block.type === 'paragraph') {
//             // Clean the content by removing surrounding quotes
//             let htmlContent = block.data.text;
            
//             // Remove surrounding quotes if they exist
//             if (htmlContent.startsWith('"') && htmlContent.endsWith('"')) {
//               htmlContent = htmlContent.slice(1, -1);
//             }
            
//             return (
//               <div 
//                 key={index}
//                 className="mb-6 leading-relaxed"
//                 dangerouslySetInnerHTML={{ 
//                   __html: DOMPurify.sanitize(htmlContent, {
//                     ALLOWED_TAGS: [
//                       'p', 'br', 'strong', 'em', 'u', 'h1', 'h2', 'h3', 
//                       'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'blockquote'
//                     ],
//                     ALLOWED_ATTR: ['href', 'target', 'class']
//                   })
//                 }}
//               />
//             );
//           }
//           return null;
//         });
//       }

//       // Fallback for old content format
//       let content = post.content_json.toString();
//       if (content.startsWith('"') && content.endsWith('"')) {
//         content = content.slice(1, -1);
//       }

//       return (
//         <div 
//           className="prose prose-lg max-w-none"
//           dangerouslySetInnerHTML={{ 
//             __html: DOMPurify.sanitize(content, {
//               ALLOWED_TAGS: [
//                 'p', 'br', 'strong', 'em', 'u', 'h1', 'h2', 'h3', 
//                 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'blockquote'
//               ],
//               ALLOWED_ATTR: ['href', 'target', 'class']
//             })
//           }}
//         />
//       );
//     } catch (error) {
//       console.error('Error rendering content:', error);
//       return <p>Error displaying content</p>;
//     }
//   };

//   if (!post) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white">
//       {/* Hero Section with Featured Image */}
//       <div className="w-full h-[60vh] relative bg-gray-900">
//         {post.featured_image ? (
//           <img 
//             src={post.featured_image} 
//             alt={post.title}
//             className="w-full h-full object-cover opacity-70"
//           />
//         ) : (
//           <div className="w-full h-full bg-gradient-to-r from-[#c99f20] to-gray-200" />
//         )}
        
//         <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
//           {isAdmin && (
//             <Link 
//               to={`/admin/blogs/edit/${post.slug}`}
//               className="absolute top-0 right-0 bg-[#c99f20] text-white px-4 py-2 rounded-md shadow-md hover:bg-[#b08b1a] transition-colors duration-300"
//             >
//               Edit Post
//             </Link>
//           )}
//           <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
//             {post.title}
//           </h1>
//           <p className="text-xl text-gray-200">
//             Published on {formatDate(post.created_at)}
//           </p>
//         </div>
//       </div>

//       {/* Content Section */}
//       <div className="container mx-auto px-4 py-12">
//         {/* Top Ad */}
//         <div className="max-w-4xl mx-auto mb-8">
//           <AdComponent adSlot="YOUR-BLOG-POST-AD-1" />
//         </div>

//         <div className="flex flex-col lg:flex-row gap-8">
//           {/* Main Content */}
//           <article className="lg:flex-grow lg:w-3/4 bg-white rounded-2xl shadow-xl overflow-hidden">
//             <div className="p-8 md:p-12">
//               <div className="prose prose-lg max-w-none">
//                 <style>
//                   {`
//                     .prose p {
//                       margin-bottom: 1.5em;
//                       line-height: 1.8;
//                       font-size: 1.1rem;
//                     }
//                     .prose h1, .prose h2, .prose h3, .prose h4 {
//                       margin-top: 1.5em;
//                       margin-bottom: 0.8em;
//                       font-weight: 600;
//                       color: #1a202c;
//                     }
//                     .prose ul, .prose ol {
//                       margin-left: 1.5em;
//                       margin-bottom: 1.5em;
//                     }
//                     .prose li {
//                       margin-bottom: 0.5em;
//                     }
//                     .prose strong {
//                       font-weight: 600;
//                       color: #2d3748;
//                     }
//                     .prose blockquote {
//                       border-left: 4px solid #c99f20;
//                       padding-left: 1em;
//                       margin-left: 0;
//                       font-style: italic;
//                       margin-bottom: 1.5em;
//                       background-color: #f7fafc;
//                       padding: 1em;
//                       border-radius: 0.5em;
//                     }
//                     .prose a {
//                       color: #c99f20;
//                       text-decoration: underline;
//                       transition: color 0.2s;
//                     }
//                     .prose a:hover {
//                       color: #b08b1a;
//                     }
//                   `}
//                 </style>
//                 {renderContent()}
//               </div>
//             </div>
//           </article>

//           {/* Sidebar with Ad */}
//           <aside className="lg:w-1/4">
//             <div className="sticky top-8">
//               <AdComponent 
//                 adSlot="3869436166" 
//                 format="vertical"
//               />
//             </div>
//           </aside>
//         </div>

//         {/* Bottom Ad */}
//         <div className="max-w-4xl mx-auto mt-8">
//           <AdComponent adSlot="3869436166" />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default BlogPost;


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api/axiosConfig';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import AdComponent from '../AdComponent';

function BlogPost() {
  const [post, setPost] = useState(null);
  const { slug } = useParams();
  const [isAdmin, setIsAdmin] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    // Check admin status when auth changes
    if (auth && auth.user && auth.user.user_type === 'Admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [auth]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await api.get(`/api/blog/posts/${slug}/`);
        console.log('Fetched post data:', response.data); // Debug log
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    if (slug) {
      fetchPost();
    }
  }, [slug]);

  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'No date available';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Invalid Date';
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid Date';
    }
  };

  const renderContent = () => {
    try {
      if (!post.content_json) return null;

      // Handle the blocks structure
      if (post.content_json.blocks) {
        return post.content_json.blocks.map((block, index) => {
          if (block.type === 'paragraph') {
            // Clean the content by removing surrounding quotes
            let htmlContent = block.data.text;
            
            // Remove surrounding quotes if they exist
            if (htmlContent.startsWith('"') && htmlContent.endsWith('"')) {
              htmlContent = htmlContent.slice(1, -1);
            }
            
            return (
              <div 
                key={index}
                className="mb-6 leading-relaxed"
                dangerouslySetInnerHTML={{ 
                  __html: DOMPurify.sanitize(htmlContent, {
                    ALLOWED_TAGS: [
                      'p', 'br', 'strong', 'em', 'u', 'h1', 'h2', 'h3', 
                      'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'blockquote'
                    ],
                    ALLOWED_ATTR: ['href', 'target', 'class']
                  })
                }}
              />
            );
          }
          return null;
        });
      }

      // Fallback for old content format
      let content = post.content_json.toString();
      if (content.startsWith('"') && content.endsWith('"')) {
        content = content.slice(1, -1);
      }

      return (
        <div 
          className="prose prose-lg max-w-none"
          dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(content, {
              ALLOWED_TAGS: [
                'p', 'br', 'strong', 'em', 'u', 'h1', 'h2', 'h3', 
                'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'blockquote'
              ],
              ALLOWED_ATTR: ['href', 'target', 'class']
            })
          }}
        />
      );
    } catch (error) {
      console.error('Error rendering content:', error);
      return <p>Error displaying content</p>;
    }
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white">
      {/* Hero Section with Featured Image */}
      <div className="w-full h-[60vh] relative bg-gray-900">
        {post.featured_image ? (
          <img 
            src={post.featured_image} 
            alt={post.title}
            className="w-full h-full object-cover opacity-70"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-r from-[#c99f20] to-gray-200" />
        )}
        
        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          {isAdmin && (
            <Link 
              to={`/admin/blogs/edit/${post.slug}`}
              className="absolute top-0 right-0 bg-[#c99f20] text-white px-4 py-2 rounded-md shadow-md hover:bg-[#b08b1a] transition-colors duration-300"
            >
              Edit Post
            </Link>
          )}
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
            {post.title}
          </h1>
          <p className="text-xl text-gray-200">
            Published on {formatDate(post.created_at)}
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-12">
        {/* Top Ad */}
        <div className="max-w-4xl mx-auto mb-8">
          <AdComponent adSlot="YOUR-BLOG-POST-AD-1" />
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <article className="lg:flex-grow lg:w-3/4 bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="p-8 md:p-12">
              <div className="prose prose-lg max-w-none">
                <style>
                  {`
                    .prose p {
                      margin-bottom: 1.5em;
                      line-height: 1.8;
                      font-size: 1.1rem;
                    }
                    .prose h1, .prose h2, .prose h3, .prose h4 {
                      margin-top: 1.5em;
                      margin-bottom: 0.8em;
                      font-weight: 600;
                      color: #1a202c;
                    }
                    .prose ul, .prose ol {
                      margin-left: 1.5em;
                      margin-bottom: 1.5em;
                    }
                    .prose li {
                      margin-bottom: 0.5em;
                    }
                    .prose strong {
                      font-weight: 600;
                      color: #2d3748;
                    }
                    .prose blockquote {
                      border-left: 4px solid #c99f20;
                      padding-left: 1em;
                      margin-left: 0;
                      font-style: italic;
                      margin-bottom: 1.5em;
                      background-color: #f7fafc;
                      padding: 1em;
                      border-radius: 0.5em;
                    }
                    .prose a {
                      color: #c99f20;
                      text-decoration: underline;
                      transition: color 0.2s;
                    }
                    .prose a:hover {
                      color: #b08b1a;
                    }
                  `}
                </style>
                {renderContent()}
              </div>
            </div>
          </article>

          {/* Sidebar with Ad */}
          <aside className="lg:w-1/4">
            <div className="sticky top-8">
              <AdComponent 
                adSlot="3869436166" 
                format="vertical"
              />
            </div>
          </aside>
        </div>

        {/* Bottom Ad */}
        <div className="max-w-4xl mx-auto mt-8">
          <AdComponent adSlot="3869436166" />
        </div>
      </div>
    </div>
  );
}

export default BlogPost;

