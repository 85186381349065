import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AdComponent = ({ adSlot, format = 'auto' }) => {
  const location = useLocation();

  useEffect(() => {
    // Load ads when component mounts
    if (window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, []);

  // Only render ads on blog-related pages
  if (!location.pathname.includes('/blog')) {
    return null;
  }

  return (
    <ins
      className="adsbygoogle"
      style={{ 
        display: 'block',
        width: '100%',
        minHeight: '250px'
      }}
      data-ad-client="ca-pub-1854380647465998"
      data-ad-slot={adSlot}
      data-ad-format={format}
      data-full-width-responsive="false"
      data-ad-layout="in-article"
    />
  );
};

export default AdComponent; 
