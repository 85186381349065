// import React, { useEffect, useState } from 'react';
// import Navbar from '../Layout/Navbar';
// import Footer from '../Layout/Footer/Footer';
// import { useSelector } from 'react-redux';
// import api from '../../api/axiosConfig';
// import { Link, useNavigate } from 'react-router-dom';
// import './Home.css';
// import TypeWriterText from './Typerwriter';
// import WhatsAppButton from '../WhatsApp';
// import ScrollToTopButton from '../ScrollToTop';
// import EmailButton from '../EmailUs';
// import { FaAccessibleIcon, FaLockOpen } from 'react-icons/fa';
// import JsonData from '../../data/data.json'
// import { useDispatch } from 'react-redux'
// import { useRef } from 'react';
// import HomeSections from './HomeSections';
// import HomeArticles from './HomeArticles';
// import Testimonials from './Testimonials';
// import About, { AboutUs } from './About';
// import MeetOurTeam from './MeetOurTeam';
// import Features from './Features';
// import hellow from '../../assets/icons/home-sections/New entries-cuate.svg';
// import hello from '../../assets/icons/home-sections/Creative writing-cuate.svg'





// function Home() {
//    const [landingPageData, setLandingPageData] = useState({});
//    const user = useSelector(state => state.user);
//   useEffect(() => {
//     try{
//     if(user.user_type === 'User'){
//       navigate('/user')
//     } else if(user.user_type === 'Tutor'){
//       navigate('/tutor')
//     } else if (user.user_type === 'Admin'){
//       navigate('/admin')
//     }
//   } catch (error) {
//   }

//     setLandingPageData(JsonData);
//   }, []);
//   const [categoryData, setCategoryData] = useState([]);
//   const [skillsData, setSkillsData] = useState([]);
//   const [uniqueStates, setUniqueStates] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const navigate = useNavigate();
//     const dispatch = useDispatch()
//     const fullName = useRef()
//     const email = useRef()
//     const message = useRef()


//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const categories = await api.get('/users/user-categories/');
//         const skills = await api.get('/users/user-skills/');

//         setCategoryData(categories.data);
//         setSkillsData(skills.data)
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     }
//     fetchData();
//   }, []);

//   const fetchUniqueStates = async () => {
//     try {
//       const response = await api.get('/users/user-locations/');
//       setUniqueStates(response.data.states);
//     } catch (error) {
//       console.error('Error fetching unique states:', error);
//     }
//   };

//   useEffect(() => {
//     fetchUniqueStates();
//   }, []);

//   const handleSearch = (e) => {
//     e.preventDefault();
//     const encodedSearchQuery = encodeURIComponent(searchQuery);
//     navigate(`/find-tutor?search=${encodedSearchQuery}`);
//   };

//   const handleCategoryClick = (categoryId) => {
//     navigate(`/find-tutor?category=${categoryId}`);
//   };

//   console.log("categories: ", categoryData)
//   console.log("skills: ", skillsData)

//   return (
//     <div className="w-full">
//       <Navbar />
//       <div className="flex flex-col lg:flex-row bg-white mt-12">
//         <div className="flex flex-col justify-center items-center lg:items-start lg:w-1/2 px-4 md:px-16 py-8 lg:py-0" 
//              style={{ 
//                minHeight: "400px",
//                height: "auto",
//                background: 'linear-gradient(to bottom ,#AD9551 , goldenrod)',
//                borderRadius: '0px'
//              }}>
//           <div className="text-center lg:text-left w-full">
//             <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold text-white drop-shadow-md">
//               <TypeWriterText />
//             </h2>
//             <p className="mt-4 text-sm md:text-base lg:text-lg text-white font-serif">
//               Elevate your writing with our services! We offer professional writing services that combine quality, affordability, and creativity. Our commitment is simple: to deliver exceptional results that exceed your expectations.
//             </p>
            
//             <div className="mt-6 relative w-full">
//               <a href='/signup'>
//                 <button className="get-started-btn">
//                   Get Started <FaLockOpen className='icon'/>
//                 </button>
//               </a>
//             </div>
//           </div>
//         </div>

//         <div className="lg:w-1/2 bg-white flex items-center justify-center p-4 md:p-8">
//           <div className="bg-white rounded-lg p-4 md:p-6 shadow-lg w-full max-w-md">
//             <div className="space-y-3 md:space-y-4">
//               <div className="bg-gradient-to-r from-[#AD9551] to-yellow-500 text-white rounded-lg p-3 md:p-4">
//                 <p className="text-base md:text-lg font-semibold animate-pulse pt-2 mt-1">
//                   <span className="inline-block animate-heartbeat text-xl md:text-2xl">
//                     Get 20% OFF Your First Order! 🎉
//                   </span>
//                 </p>
//                 <div className="mt-4 flex items-center justify-between bg-white/20 rounded-md p-3">
//                   <code className="text-lg md:text-xl font-mono font-bold">5FTTY5</code>
//                   <span className="text-sm ml-3 text-white/90">
//                     Use this code when registering to get 20% off your first order
//                   </span>
//                 </div>
//               </div>
              
//               <div className="border-t border-gray-200 pt-3 md:pt-4">
//                 <h4 className="font-semibold text-gray-700 mb-2">🎁 Refer & Earn More!</h4>
//                 <ul className="space-y-2 text-xs md:text-sm text-gray-600">
//                   <li className="flex items-center">
//                     <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
//                       <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
//                     </svg>
//                     Generate your code from your account and share with friends
//                   </li>
//                   <li className="flex items-center">
//                     <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
//                       <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
//                     </svg>
//                     They get 20% off their first order
//                   </li>
//                   <li className="flex items-center">
//                     <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
//                       <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
//                     </svg>
//                     You get 20% off your next order
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col md:flex-row justify-center items-center bg-gradient-to-t from-gray-200 to-gray-200">
//         <div className="mb-4 mt-5 sm:mt-4 mx-2">
//           <a className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-sm py-6 px-6 flex flex-col items-center md:flex-row md:items-center" href="#">
//             <p className="text-2xl font-bold text-gray-900">
//               <span style={{ color:'#AD9551', fontSize:'16px'}}>Over</span> 200
//             </p>
//             <p className="text-sm font-semibold text-gray-900 group-hover:text-gray-900 mt-2 leading-6 mx-1">
//               <span style={{ color:'#AD9551', fontSize:'16px'}}>Writers</span>
//             </p>
//           </a>
//         </div>
//         <div className="mb-4 mt-5 sm:mt-4 mx-2">
//           <a className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-sm py-6 px-6 flex flex-col items-center md:flex-row md:items-center" href="#">
//             <p className="text-2xl font-bold text-gray-900 group-hover:text-gray-700">
//               <span style={{ color:'#AD9551', fontSize:'16px'}}>Over</span> 10,000
//             </p>
//             <p className="text-sm font-semibold text-gray-900 group-hover:text-gray-700 mt-2 leading-6 mx-1">
//               <span style={{ color:'#AD9551', fontSize:'16px'}}>Users worldwide</span>
//             </p>
//           </a>
//         </div>
//         <div className="mb-4 mt-5 sm:mt-4 mx-2">
//           <a className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-sm py-6 px-6 flex flex-col items-center md:flex-row md:items-center" href="#">
//             <p className="text-2xl font-bold text-gray-900 group-hover:text-gray-700">
//               <span style={{ color:'#AD9551', fontSize:'16px'}}>Over</span> 100
//             </p>
//             <p className="text-sm font-semibold text-gray-900 group-hover:text-gray-700 mt-2 leading-6 mx-1">
//               <span style={{ color:'#AD9551', fontSize:'16px'}}>Countries</span>
//             </p>
//           </a>
//         </div>
//       </div>

// 			<HomeSections />
//       <About />
//       <MeetOurTeam />
// 			<Testimonials />
// 			<HomeArticles />



//       <Footer />
//       <WhatsAppButton />
//       <ScrollToTopButton />
//       <EmailButton />
//     </div>
//   );
// }

// export default Home;




import React, { useEffect, useState } from 'react';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer/Footer';
import { useSelector } from 'react-redux';
import api from '../../api/axiosConfig';
import { Link, useNavigate } from 'react-router-dom';
import './Home.css';
import TypeWriterText from './Typerwriter';
import WhatsAppButton from '../WhatsApp';
import ScrollToTopButton from '../ScrollToTop';
import EmailButton from '../EmailUs';
import { FaAccessibleIcon, FaLockOpen } from 'react-icons/fa';
import JsonData from '../../data/data.json'
import { useDispatch } from 'react-redux'
import { useRef } from 'react';
import HomeSections from './HomeSections';
import HomeArticles from './HomeArticles';
import Testimonials from './Testimonials';
import About, { AboutUs } from './About';
import MeetOurTeam from './MeetOurTeam';
import Features from './Features';
import hellow from '../../assets/icons/home-sections/New entries-cuate.svg';
import hello from '../../assets/icons/home-sections/Creative writing-cuate.svg'





function Home() {
   const [landingPageData, setLandingPageData] = useState({});
   const user = useSelector(state => state.user);
  useEffect(() => {
    try{
    if(user.user_type === 'User'){
      navigate('/user')
    } else if(user.user_type === 'Tutor'){
      navigate('/tutor')
    } else if (user.user_type === 'Admin'){
      navigate('/admin')
    }
  } catch (error) {
  }

    setLandingPageData(JsonData);
  }, []);
  const [categoryData, setCategoryData] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [uniqueStates, setUniqueStates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
    const dispatch = useDispatch()
    const fullName = useRef()
    const email = useRef()
    const message = useRef()


  useEffect(() => {
    async function fetchData() {
      try {
        const categories = await api.get('/users/user-categories/');
        const skills = await api.get('/users/user-skills/');

        setCategoryData(categories.data);
        setSkillsData(skills.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const fetchUniqueStates = async () => {
    try {
      const response = await api.get('/users/user-locations/');
      setUniqueStates(response.data.states);
    } catch (error) {
      console.error('Error fetching unique states:', error);
    }
  };

  useEffect(() => {
    fetchUniqueStates();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const encodedSearchQuery = encodeURIComponent(searchQuery);
    navigate(`/find-tutor?search=${encodedSearchQuery}`);
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/find-tutor?category=${categoryId}`);
  };

  console.log("categories: ", categoryData)
  console.log("skills: ", skillsData)

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col lg:flex-row bg-white mt-12">
        <div className="flex flex-col justify-center items-center lg:items-start lg:w-1/2 px-4 md:px-16 py-8 lg:py-0" 
             style={{ 
               minHeight: "400px",
               height: "auto",
               background: 'linear-gradient(to bottom ,#AD9551 , goldenrod)',
               borderRadius: '0px'
             }}>
          <div className="text-center lg:text-left w-full">
            <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold text-white drop-shadow-md">
              <TypeWriterText />
            </h2>
            <p className="mt-4 text-sm md:text-base lg:text-lg text-white font-serif">
              Elevate your writing with our services! We offer professional writing services that combine quality, affordability, and creativity. Our commitment is simple: to deliver exceptional results that exceed your expectations.
            </p>
            
            <div className="mt-6 relative w-full">
              <a href='/signup'>
                <button className="get-started-btn">
                  Get Started <FaLockOpen className='icon'/>
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="lg:w-1/2 bg-white flex items-center justify-center p-4 md:p-8">
          <div className="bg-white rounded-lg p-4 md:p-6 shadow-lg w-full max-w-md">
            <div className="space-y-3 md:space-y-4">
              <div className="bg-gradient-to-r from-[#AD9551] to-yellow-500 text-white rounded-lg p-3 md:p-4">
                <p className="text-base md:text-lg font-semibold animate-pulse pt-2 mt-1">
                  <span className="inline-block animate-heartbeat text-xl md:text-2xl">
                    Get 20% OFF Your First Order! 🎉
                  </span>
                </p>
                <div className="mt-4 flex items-center justify-between bg-white/20 rounded-md p-3">
                  <code className="text-lg md:text-xl font-mono font-bold">5FTTY5</code>
                  <span className="text-sm ml-3 text-white/90">
                    Use this code when registering to get 20% off your first order
                  </span>
                </div>
              </div>
              
              <div className="border-t border-gray-200 pt-3 md:pt-4">
                <h4 className="font-semibold text-gray-700 mb-2">🎁 Refer & Earn More!</h4>
                <ul className="space-y-2 text-xs md:text-sm text-gray-600">
                  <li className="flex items-center">
                    <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                    </svg>
                    Generate your code from your account and share with friends
                  </li>
                  <li className="flex items-center">
                    <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                    </svg>
                    They get 20% off their first order
                  </li>
                  <li className="flex items-center">
                    <svg className="w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                    </svg>
                    You get 20% off your next order
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center bg-white py-8 px-4">
        <div className="flex-1 text-center mx-4 mb-6 md:mb-0">
          <div className="group hover:scale-105 transition-transform duration-200">
            <p className="text-4xl font-bold text-gray-800 mb-1">
              200<span className="text-[#AD9551] text-2xl">+</span>
            </p>
            <p className="text-[#AD9551] font-semibold uppercase tracking-wider text-sm">
              Writers
            </p>
          </div>
        </div>

        <div className="flex-1 text-center mx-4 mb-6 md:mb-0">
          <div className="group hover:scale-105 transition-transform duration-200">
            <p className="text-4xl font-bold text-gray-800 mb-1">
              10,000<span className="text-[#AD9551] text-2xl">+</span>
            </p>
            <p className="text-[#AD9551] font-semibold uppercase tracking-wider text-sm">
              Users Worldwide
            </p>
          </div>
        </div>

        <div className="flex-1 text-center mx-4">
          <div className="group hover:scale-105 transition-transform duration-200">
            <p className="text-4xl font-bold text-gray-800 mb-1">
              100<span className="text-[#AD9551] text-2xl">+</span>
            </p>
            <p className="text-[#AD9551] font-semibold uppercase tracking-wider text-sm">
              Countries
            </p>
          </div>
        </div>
      </div>

			<HomeSections />
      <About />
      <MeetOurTeam />
			<Testimonials />
			<HomeArticles />



      <Footer />
      <WhatsAppButton />
      <ScrollToTopButton />
      <EmailButton />
    </div>
  );
}

export default Home;